export interface chracter {
  symbol: string;
  color: number;
  flags: number;
}

export type row = chracter[];

export type screen = row[];

const THIRD_PARTY_EVENT_ID_MIN = 0x00011000;

export enum PmdgEventID {
  CDU_L1 = THIRD_PARTY_EVENT_ID_MIN + 328,
  CDU_L2 = THIRD_PARTY_EVENT_ID_MIN + 329,
  CDU_L3 = THIRD_PARTY_EVENT_ID_MIN + 330,
  CDU_L4 = THIRD_PARTY_EVENT_ID_MIN + 331,
  CDU_L5 = THIRD_PARTY_EVENT_ID_MIN + 332,
  CDU_L6 = THIRD_PARTY_EVENT_ID_MIN + 333,
  CDU_R1 = THIRD_PARTY_EVENT_ID_MIN + 334,
  CDU_R2 = THIRD_PARTY_EVENT_ID_MIN + 335,
  CDU_R3 = THIRD_PARTY_EVENT_ID_MIN + 336,
  CDU_R4 = THIRD_PARTY_EVENT_ID_MIN + 337,
  CDU_R5 = THIRD_PARTY_EVENT_ID_MIN + 338,
  CDU_R6 = THIRD_PARTY_EVENT_ID_MIN + 339,
  CDU_INIT_REF = THIRD_PARTY_EVENT_ID_MIN + 340,
  CDU_RTE = THIRD_PARTY_EVENT_ID_MIN + 341,
  CDU_DEP_ARR = THIRD_PARTY_EVENT_ID_MIN + 342,
  CDU_ALTN = THIRD_PARTY_EVENT_ID_MIN + 343,
  CDU_VNAV = THIRD_PARTY_EVENT_ID_MIN + 344,
  CDU_FIX = THIRD_PARTY_EVENT_ID_MIN + 345,
  CDU_LEGS = THIRD_PARTY_EVENT_ID_MIN + 346,
  CDU_HOLD = THIRD_PARTY_EVENT_ID_MIN + 347,
  CDU_FMCCOMMAND = THIRD_PARTY_EVENT_ID_MIN + 3471,
  CDU_PROG = THIRD_PARTY_EVENT_ID_MIN + 348,
  CDU_EXEC = THIRD_PARTY_EVENT_ID_MIN + 349,
  CDU_MENU = THIRD_PARTY_EVENT_ID_MIN + 350,
  CDU_NAV_RAD = THIRD_PARTY_EVENT_ID_MIN + 351,
  CDU_PREV_PAGE = THIRD_PARTY_EVENT_ID_MIN + 352,
  CDU_NEXT_PAGE = THIRD_PARTY_EVENT_ID_MIN + 353,
  CDU_1 = THIRD_PARTY_EVENT_ID_MIN + 354,
  CDU_2 = THIRD_PARTY_EVENT_ID_MIN + 355,
  CDU_3 = THIRD_PARTY_EVENT_ID_MIN + 356,
  CDU_4 = THIRD_PARTY_EVENT_ID_MIN + 357,
  CDU_5 = THIRD_PARTY_EVENT_ID_MIN + 358,
  CDU_6 = THIRD_PARTY_EVENT_ID_MIN + 359,
  CDU_7 = THIRD_PARTY_EVENT_ID_MIN + 360,
  CDU_8 = THIRD_PARTY_EVENT_ID_MIN + 361,
  CDU_9 = THIRD_PARTY_EVENT_ID_MIN + 362,
  CDU_DOT = THIRD_PARTY_EVENT_ID_MIN + 363,
  CDU_0 = THIRD_PARTY_EVENT_ID_MIN + 364,
  CDU_PLUS_MINUS = THIRD_PARTY_EVENT_ID_MIN + 365,
  CDU_A = THIRD_PARTY_EVENT_ID_MIN + 366,
  CDU_B = THIRD_PARTY_EVENT_ID_MIN + 367,
  CDU_C = THIRD_PARTY_EVENT_ID_MIN + 368,
  CDU_D = THIRD_PARTY_EVENT_ID_MIN + 369,
  CDU_E = THIRD_PARTY_EVENT_ID_MIN + 370,
  CDU_F = THIRD_PARTY_EVENT_ID_MIN + 371,
  CDU_G = THIRD_PARTY_EVENT_ID_MIN + 372,
  CDU_H = THIRD_PARTY_EVENT_ID_MIN + 373,
  CDU_I = THIRD_PARTY_EVENT_ID_MIN + 374,
  CDU_J = THIRD_PARTY_EVENT_ID_MIN + 375,
  CDU_K = THIRD_PARTY_EVENT_ID_MIN + 376,
  CDU_L = THIRD_PARTY_EVENT_ID_MIN + 377,
  CDU_M = THIRD_PARTY_EVENT_ID_MIN + 378,
  CDU_N = THIRD_PARTY_EVENT_ID_MIN + 379,
  CDU_O = THIRD_PARTY_EVENT_ID_MIN + 380,
  CDU_P = THIRD_PARTY_EVENT_ID_MIN + 381,
  CDU_Q = THIRD_PARTY_EVENT_ID_MIN + 382,
  CDU_R = THIRD_PARTY_EVENT_ID_MIN + 383,
  CDU_S = THIRD_PARTY_EVENT_ID_MIN + 384,
  CDU_T = THIRD_PARTY_EVENT_ID_MIN + 385,
  CDU_U = THIRD_PARTY_EVENT_ID_MIN + 386,
  CDU_V = THIRD_PARTY_EVENT_ID_MIN + 387,
  CDU_W = THIRD_PARTY_EVENT_ID_MIN + 388,
  CDU_X = THIRD_PARTY_EVENT_ID_MIN + 389,
  CDU_Y = THIRD_PARTY_EVENT_ID_MIN + 390,
  CDU_Z = THIRD_PARTY_EVENT_ID_MIN + 391,
  CDU_SPACE = THIRD_PARTY_EVENT_ID_MIN + 392,
  CDU_DEL = THIRD_PARTY_EVENT_ID_MIN + 393,
  CDU_SLASH = THIRD_PARTY_EVENT_ID_MIN + 394,
  CDU_CLR = THIRD_PARTY_EVENT_ID_MIN + 395,
}

export enum MyEventID {
  CDU_L1,
  CDU_L2,
  CDU_L3,
  CDU_L4,
  CDU_L5,
  CDU_L6,
  CDU_R1,
  CDU_R2,
  CDU_R3,
  CDU_R4,
  CDU_R5,
  CDU_R6,
  CDU_INIT_REF,
  CDU_RTE,
  CDU_DEP_ARR,
  CDU_ALTN,
  CDU_VNAV,
  CDU_FIX,
  CDU_LEGS,
  CDU_HOLD,
  CDU_FMCCOMMAND,
  CDU_PROG,
  CDU_EXEC,
  CDU_MENU,
  CDU_NAV_RAD,
  CDU_PREV_PAGE,
  CDU_NEXT_PAGE,
  CDU_1,
  CDU_2,
  CDU_3,
  CDU_4,
  CDU_5,
  CDU_6,
  CDU_7,
  CDU_8,
  CDU_9,
  CDU_DOT,
  CDU_0,
  CDU_PLUS_MINUS,
  CDU_A,
  CDU_B,
  CDU_C,
  CDU_D,
  CDU_E,
  CDU_F,
  CDU_G,
  CDU_H,
  CDU_I,
  CDU_J,
  CDU_K,
  CDU_L,
  CDU_M,
  CDU_N,
  CDU_O,
  CDU_P,
  CDU_Q,
  CDU_R,
  CDU_S,
  CDU_T,
  CDU_U,
  CDU_V,
  CDU_W,
  CDU_X,
  CDU_Y,
  CDU_Z,
  CDU_SPACE,
  CDU_DEL,
  CDU_SLASH,
  CDU_CLR,
}
