import { Socket, io } from "socket.io-client";
import FMS from "./fms/fms";
import { useEffect, useState } from "react";

export default function SocketProvider({
  Endpoint,
  setEndPoint,
}: {
  Endpoint: string;
  setEndPoint: React.Dispatch<React.SetStateAction<string | null>>;
}) {
  const [socket, setSocket] = useState(null as null | Socket);

  const _socket = io(Endpoint, {
    extraHeaders: {
      targetAddressSpace: "private",
    },
  }).connect();

  _socket.on("connect", () => {
    if (!socket && _socket.connected) {
      setSocket(_socket);
    }
  });

  useEffect(() => {
    return () => {
      _socket.disconnect();
    };
  }, []);

  const reset = () => {
    setEndPoint(null);
  };

  if (!socket || !socket.connected) {
    return (
      <section className="flex flex-col gap-2 p-24">
        <h1 className="text-3xl mb-4">FSMS PMDG CDU</h1>
        <p className="text-xl">Connecting...</p>

        <button
          className="px-4 py-2 bg-slate-400 text-lg font-bold rounded-md shadow-md"
          onClick={reset}
        >
          Try another endpoint
        </button>
      </section>
    );
  }

  return <FMS socket={socket} />;
}
