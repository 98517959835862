import { MyEventID } from "../../types";
import { Area } from "@qiuz/react-image-map";
import { buttonClassName } from "./classes";
import { useDebouncedClick } from "../../utils/useDebouncedClick";

export default function NumericButtons({
  handleClick,
}: {
  handleClick: (MyEventID: MyEventID) => void;
}) {
  const areaStyles: Pick<Area, "width" | "height" | "style"> = {
    width: "7.3%",
    height: "4.5%",
    style: { mixBlendMode: "multiply" },
  };

  const buttons = [
    // First
    { left: "11.9%", top: "73.15%", EventID: MyEventID.CDU_1 },
    { left: "21.5%", top: "73.15%", EventID: MyEventID.CDU_2 },
    { left: "31.35%", top: "73.15%", EventID: MyEventID.CDU_3 },
    // Second
    { left: "11.9%", top: "79.4%", EventID: MyEventID.CDU_4 },
    { left: "21.5%", top: "79.4%", EventID: MyEventID.CDU_5 },
    { left: "31.35%", top: "79.4%", EventID: MyEventID.CDU_6 },
    // Third
    { left: "11.9%", top: "85.5%", EventID: MyEventID.CDU_7 },
    { left: "21.5%", top: "85.5%", EventID: MyEventID.CDU_8 },
    { left: "31.35%", top: "85.5%", EventID: MyEventID.CDU_9 },
    // Fourth
    { left: "11.9%", top: "91.6%", EventID: MyEventID.CDU_DOT },
    { left: "21.5%", top: "91.6%", EventID: MyEventID.CDU_0 },
    { left: "31.35%", top: "91.6%", EventID: MyEventID.CDU_PLUS_MINUS },
  ];

  const debouncedClick = useDebouncedClick(handleClick);

  return buttons.map(({ left, top, EventID }) => ({
    ...areaStyles,
    left,
    top,
    render: () => (
      <button
        className={`${buttonClassName} !rounded-full`}
        onMouseUp={() => {
          debouncedClick(EventID);
        }}
        onTouchStart={() => {
          debouncedClick(EventID);
        }}
      />
    ),
  })) as Area[];
}
