import { MyEventID } from "../../types";
import { Area } from "@qiuz/react-image-map";
import { buttonClassName } from "./classes";
import { useDebouncedClick } from "../../utils/useDebouncedClick";

export default function ExecButton({
  handleClick,
}: {
  handleClick: (MyEventID: MyEventID) => void;
}) {
  const areaStyles: Pick<Area, "width" | "height" | "style"> = {
    width: "10.5%",
    height: "3.7%",
    style: { mixBlendMode: "multiply" },
  };

  const debouncedClick = useDebouncedClick(handleClick);

  return {
    ...areaStyles,
    left: "76.7%",
    top: "56.15%",
    render: () => (
      <button
        className={buttonClassName}
        onMouseUp={() => {
          debouncedClick(MyEventID.CDU_EXEC);
        }}
        onTouchStart={() => {
          debouncedClick(MyEventID.CDU_EXEC);
        }}
      />
    ),
  };
}
