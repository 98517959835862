import { MyEventID } from "../../types";
import { Area } from "@qiuz/react-image-map";
import { buttonClassName } from "./classes";
import { useDebouncedClick } from "../../utils/useDebouncedClick";

export default function SideButtons({
  handleClick,
}: {
  handleClick: (MyEventID: MyEventID) => void;
}) {
  const areaStyles: Pick<Area, "width" | "height" | "style"> = {
    width: "7%",
    height: "3%",
    style: { mixBlendMode: "multiply" },
  };

  const buttons = [
    // Left
    { left: "1%", top: "11.5%", EventID: MyEventID.CDU_L1 },
    { left: "1%", top: "16.5%", EventID: MyEventID.CDU_L2 },
    { left: "1%", top: "21.5%", EventID: MyEventID.CDU_L3 },
    { left: "1%", top: "26.5%", EventID: MyEventID.CDU_L4 },
    { left: "1%", top: "31.6%", EventID: MyEventID.CDU_L5 },
    { left: "1%", top: "36.8%", EventID: MyEventID.CDU_L6 },
    // Right
    { left: "91.5%", top: "11.5%", EventID: MyEventID.CDU_R1 },
    { left: "91.5%", top: "16.5%", EventID: MyEventID.CDU_R2 },
    { left: "91.5%", top: "21.5%", EventID: MyEventID.CDU_R3 },
    { left: "91.5%", top: "26.5%", EventID: MyEventID.CDU_R4 },
    { left: "91.5%", top: "31.6%", EventID: MyEventID.CDU_R5 },
    { left: "91.5%", top: "36.8%", EventID: MyEventID.CDU_R6 },
  ];

  const debouncedClick = useDebouncedClick(handleClick);

  return buttons.map(({ left, top, EventID }) => ({
    ...areaStyles,
    left,
    top,
    render: () => (
      <button
        className={buttonClassName}
        onMouseUp={() => {
          debouncedClick(EventID);
        }}
        onTouchStart={() => {
          debouncedClick(EventID);
        }}
      />
    ),
  })) as Area[];
}
