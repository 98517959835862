import { MyEventID } from "../../types";
import { Area } from "@qiuz/react-image-map";
import { buttonClassName } from "./classes";
import { useState } from "react";
import { useDebouncedClick } from "../../utils/useDebouncedClick";

export default function AlphabetButtons({
  handleClick,
}: {
  handleClick: (EventId: MyEventID) => void;
}) {
  const areaStyles: Pick<Area, "width" | "height" | "style"> = {
    width: "7%",
    height: "4.7%",
    style: { mixBlendMode: "multiply" },
  };

  const buttons = [
    // First
    { left: "41.6%", top: "60.8%", EventID: MyEventID.CDU_A },
    { left: "51.5%", top: "60.8%", EventID: MyEventID.CDU_B },
    { left: "61.3%", top: "60.8%", EventID: MyEventID.CDU_C },
    { left: "70.9%", top: "60.8%", EventID: MyEventID.CDU_D },
    { left: "80.5%", top: "60.8%", EventID: MyEventID.CDU_E },
    // Second
    { left: "41.6%", top: "67.1%", EventID: MyEventID.CDU_F },
    { left: "51.5%", top: "67.1%", EventID: MyEventID.CDU_G },
    { left: "61.3%", top: "67.1%", EventID: MyEventID.CDU_H },
    { left: "70.9%", top: "67.1%", EventID: MyEventID.CDU_I },
    { left: "80.5%", top: "67.1%", EventID: MyEventID.CDU_J },
    //Third
    { left: "41.6%", top: "73.2%", EventID: MyEventID.CDU_K },
    { left: "51.5%", top: "73.2%", EventID: MyEventID.CDU_L },
    { left: "61.3%", top: "73.2%", EventID: MyEventID.CDU_M },
    { left: "70.9%", top: "73.2%", EventID: MyEventID.CDU_N },
    { left: "80.5%", top: "73.2%", EventID: MyEventID.CDU_O },
    //Fourth
    { left: "41.6%", top: "79.3%", EventID: MyEventID.CDU_P },
    { left: "51.5%", top: "79.3%", EventID: MyEventID.CDU_Q },
    { left: "61.3%", top: "79.3%", EventID: MyEventID.CDU_R },
    { left: "70.9%", top: "79.3%", EventID: MyEventID.CDU_S },
    { left: "80.5%", top: "79.3%", EventID: MyEventID.CDU_T },
    //Fifth
    { left: "41.6%", top: "85.5%", EventID: MyEventID.CDU_U },
    { left: "51.5%", top: "85.5%", EventID: MyEventID.CDU_V },
    { left: "61.3%", top: "85.5%", EventID: MyEventID.CDU_W },
    { left: "70.9%", top: "85.5%", EventID: MyEventID.CDU_X },
    { left: "80.5%", top: "85.5%", EventID: MyEventID.CDU_Y },
    //Sixth
    { left: "41.6%", top: "91.6%", EventID: MyEventID.CDU_X },
    { left: "51.5%", top: "91.6%", EventID: MyEventID.CDU_SPACE },
    { left: "61.3%", top: "91.6%", EventID: MyEventID.CDU_DEL },
    { left: "70.9%", top: "91.6%", EventID: MyEventID.CDU_SLASH },
    { left: "80.5%", top: "91.6%", EventID: MyEventID.CDU_CLR },
  ];

  const debouncedClick = useDebouncedClick(handleClick);

  return buttons.map(({ left, top, EventID }) => ({
    ...areaStyles,
    left,
    top,
    render: () => (
      <button
        className={buttonClassName}
        onMouseUp={() => {
          debouncedClick(EventID);
        }}
        onTouchStart={() => {
          debouncedClick(EventID);
        }}
      />
    ),
  })) as Area[];
}
