import { Area } from "@qiuz/react-image-map";
import { screen } from "../types";

export default function Screen({
  cdu_data,
}: {
  cdu_data: {
    lines: screen;
    powered: boolean;
  };
}) {
  const textColors = [
    "text-yellow-50",
    "text-cyan-300",
    "text-green-300",
    "text-fuchsia-500",
    "text-amber-500",
    "text-red-500",
  ];
  const flagStyles = [
    "text-[150%]",
    "text-[100%]",
    "text-[80%]",
    "",
    "opacity-30",
  ];

  const screen: Area = {
    width: "70.1%",
    height: "35.9%",
    left: "14.6%",
    top: "6.5%",
    render: () => (
      <div className="flex flex-col w-full h-full rounded-lg">
        {cdu_data.lines.map((line, i) => (
          <div key={i} className="flex flex-row h-[7.142%] px-2">
            {line.map((char, j) => {
              const { color, flags, symbol } = char;
              if (color + flags > 0) {
                // console.log(char);
              }
              return (
                <div
                  key={j}
                  className={`w-[4.16%] flex flex-row justify-center items-center ${textColors[color]} ${flagStyles[flags]}`}
                >
                  {symbol}
                </div>
              );
            })}
          </div>
        ))}
      </div>
    ),
  };

  return screen;
}
