import { useEffect, useState } from "react";
import { screen, chracter, row, MyEventID } from "../types";
import { Area, ImageMap } from "@qiuz/react-image-map";
import useSound from "use-sound";
import SideButtons from "./buttons/sideButtons";
import FuncButtons from "./buttons/funcButtons";
import NumericButtons from "./buttons/numericButtons";
import AlphabetButtons from "./buttons/alphabetButtons";
import ExecButton from "./buttons/execButton";
import Annunciators from "./annunciators";
import Screen from "./screen";
import { Socket } from "socket.io-client";
import { RiCollapseDiagonalFill, RiExpandDiagonalFill } from "react-icons/ri";

const dummyCharacter: chracter = {
  color: 0,
  flags: 0,
  symbol: "-",
};

const dummyLine: row = Array(24).fill(dummyCharacter);
const dummyScreen: screen = Array(14).fill(dummyLine);

export default function FMS({ socket }: { socket: Socket }) {
  const [cduConnected, setCduConnected] = useState(false);
  const [connectionErr, setConnectionErr] = useState(null as any);

  const [play] = useSound("/click.wav", { volume: 0.5 });

  const [cdu_data, set_cdu_data] = useState({
    powered: false,
    lines: dummyScreen,
  });

  const [cdu_states, set_cdu_states] = useState({
    annunEXEC: false,
    annunDSPY: false,
    annunFAIL: false,
    annunMSG: false,
    annunOFST: false,
  });

  function onDisconnect() {
    setConnectionErr(null);
    setCduConnected(false);
  }

  function onError(err: string) {
    setConnectionErr(err);
  }

  function onCduConnected() {
    setCduConnected(true);
  }

  function onCduData(powered: boolean, lines: screen) {
    set_cdu_data({
      powered: Boolean(powered),
      lines,
    });
  }

  function onCduState(states: any) {
    set_cdu_states(states);
  }

  const connectCDU = () => {
    socket.emit("connect_cdu");
  };

  socket.on("connect", connectCDU);
  socket.on("disconnect", onDisconnect);
  socket.on("connection_error", onError);
  socket.on("connection_success", onCduConnected);
  socket.on("cdu_data", onCduData);
  socket.on("cdu_state", onCduState);

  const handleClick = (EventId: MyEventID) => {
    play();
    socket.emit("button_click", EventId);
  };

  useEffect(() => {
    return () => {
      socket.off("connect", connectCDU);
      socket.off("disconnect", onDisconnect);
      socket.off("connection_error", onError);
      socket.off("connection_success", onCduConnected);
      socket.off("cdu_data", onCduData);
      socket.off("cdu_state", onCduState);
      socket.disconnect();
    };
  }, []);

  const buttons = [
    ...SideButtons({ handleClick }),
    ...FuncButtons({ handleClick }),
    ...NumericButtons({ handleClick }),
    ...AlphabetButtons({ handleClick }),
    ExecButton({ handleClick }),
  ];

  const connectButton: Area = {
    width: "8%",
    height: "3.5%",
    left: "76%",
    top: "49.9%",
    render: () => (
      <>
        {!cduConnected && (
          <button
            className="px-2 py-1 bg-slate-700 rounded-md border-2 border-green-300 shadow-md font-medium text-sm"
            onMouseUp={connectCDU}
            onTouchStart={connectCDU}
          >
            Connect
          </button>
        )}
        {connectionErr && <p>{connectionErr}</p>}
      </>
    ),
  };

  const [expand, setExpand] = useState(false);
  const toggleExpand = () => {
    setExpand(!expand);
  };

  const expandButton: Area = {
    width: "0%",
    height: "3.5%",
    left: "95.2%",
    top: "0.4%",
    render: () => (
      <button
        className="p-1 bg-slate-700 rounded-full shadow-lg font-medium text-sm"
        onClick={toggleExpand}
      >
        {expand ? <RiCollapseDiagonalFill /> : <RiExpandDiagonalFill />}
      </button>
    ),
  };

  const mapArea: Area[] = [
    ...buttons,
    ...Annunciators({ cdu_states }),
    Screen({ cdu_data }),
    connectButton,
    expandButton,
  ];

  return (
    <section className="flex-1 flex flex-col relative">
      <ImageMap
        src="/fms.jpg"
        map={mapArea}
        className={`z-10 ${expand ? "min-w-[100dvw]" : ""}`}
      />
      <div className="absolute z-0 left-0 bottom-4 opacity-50 w-full flex flex-row justify-center">
        Copyright 2024 @ Peter K
      </div>
    </section>
  );
}
