import { useState } from "react";
import { MyEventID } from "../types";

export const useDebouncedClick = (
  handleClick: (EventID: MyEventID) => void
) => {
  const [timer, setTimer] = useState(false);

  const debouncedClick = (EventID: MyEventID) => {
    if (timer) {
      return;
    }

    setTimer(true);

    handleClick(EventID);

    setTimeout(() => {
      setTimer(false);
    }, 200);
  };

  return debouncedClick;
};
