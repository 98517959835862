import { Area } from "@qiuz/react-image-map";

export default function Annunciators({
  cdu_states,
}: {
  cdu_states: {
    annunEXEC: Boolean;
    annunDSPY: Boolean;
    annunFAIL: Boolean;
    annunMSG: Boolean;
    annunOFST: Boolean;
  };
}) {
  const { annunEXEC, annunDSPY, annunFAIL, annunMSG, annunOFST } = cdu_states;

  const execAnnunciator: Area = {
    width: "9%",
    height: "1.1%",
    left: "77.5%",
    top: "54.26%",
    render: () => (
      <div
        className={`w-full h-full rounded-lg bg-white ${
          annunEXEC ? "opacity-100" : "opacity-0"
        }`}
      />
    ),
  };

  const messageAnnunciator: Area = {
    width: "4%",
    height: "6.5%",
    left: "89.9%",
    top: "69%",
    render: () => (
      <div
        className={`w-full h-full rounded-lg flex flex-col gap-0 py-1 items-center ${
          annunMSG ? "opacity-100" : "opacity-0"
        }`}
      >
        <span className="flex-1 flex flex-col justify-center text-[100%] font-semibold leading-none">
          M
        </span>
        <span className="flex-1 flex flex-col justify-center text-[100%] font-semibold leading-none">
          S
        </span>
        <span className="flex-1 flex flex-col justify-center text-[100%] font-semibold leading-none">
          G
        </span>
      </div>
    ),
  };

  const displayAnnunciator: Area = {
    width: "4%",
    height: "6.5%",
    left: "4.8%",
    top: "69%",
    render: () => (
      <div
        className={`w-full h-full rounded-lg flex flex-col gap-0 py-1 items-center ${
          annunDSPY ? "opacity-100" : "opacity-0"
        }`}
      >
        <span className="flex-1 flex flex-col justify-center text-[80%] font-semibold leading-none">
          D
        </span>
        <span className="flex-1 flex flex-col justify-center text-[80%] font-semibold leading-none">
          S
        </span>
        <span className="flex-1 flex flex-col justify-center text-[80%] font-semibold leading-none">
          P
        </span>
        <span className="flex-1 flex flex-col justify-center text-[80%] font-semibold leading-none">
          Y
        </span>
      </div>
    ),
  };

  const failAnnunciator: Area = {
    width: "4%",
    height: "6.5%",
    left: "4.8%",
    top: "77.3%",
    render: () => (
      <div
        className={`w-full h-full rounded-lg flex flex-col gap-0 py-1 items-center ${
          annunFAIL ? "opacity-100" : "opacity-0"
        }`}
      >
        <span className="flex-1 flex flex-col justify-center text-[80%] font-semibold leading-none">
          F
        </span>
        <span className="flex-1 flex flex-col justify-center text-[80%] font-semibold leading-none">
          A
        </span>
        <span className="flex-1 flex flex-col justify-center text-[80%] font-semibold leading-none">
          I
        </span>
        <span className="flex-1 flex flex-col justify-center text-[80%] font-semibold leading-none">
          L
        </span>
      </div>
    ),
  };

  const offsetAnnunciator: Area = {
    width: "4%",
    height: "6.5%",
    left: "89.9%",
    top: "77.3%",
    render: () => (
      <div
        className={`w-full h-full rounded-lg flex flex-col gap-0 py-1 items-center ${
          annunOFST ? "opacity-100" : "opacity-0"
        }`}
      >
        <span className="flex-1 flex flex-col justify-center text-[80%] font-semibold leading-none">
          O
        </span>
        <span className="flex-1 flex flex-col justify-center text-[80%] font-semibold leading-none">
          F
        </span>
        <span className="flex-1 flex flex-col justify-center text-[80%] font-semibold leading-none">
          S
        </span>
        <span className="flex-1 flex flex-col justify-center text-[80%] font-semibold leading-none">
          T
        </span>
      </div>
    ),
  };

  return [
    execAnnunciator,
    messageAnnunciator,
    displayAnnunciator,
    failAnnunciator,
    offsetAnnunciator,
  ];
}
