import { ReactEventHandler, useState } from "react";
import SocketProvider from "./socket-provider";

function App() {
  const [endPoint, setEndPoint] = useState(null as null | string);
  const [temp, setTemp] = useState(null as null | string);

  const onSubmit: ReactEventHandler<HTMLFormElement> = e => {
    e.preventDefault();
    setEndPoint(`${temp}:5959`);
  };

  return (
    <main className="flex min-h-screen flex-col items-center justify-between bg-gradient-to-br from-[#d7af87] to-[#9f7d58] overflow-x-hidden">
      {!endPoint && (
        <form onSubmit={onSubmit} className="flex flex-col gap-2 p-24">
          <h1 className="text-3xl mb-4">FSMS PMDG CDU</h1>
          <p className="text-xl">Enter your Server Endpoint</p>

          <input
            type="text"
            onChange={event => setTemp(event.target.value)}
            placeholder="192.168.1.xxx"
            className="text-black p-1"
          />
          <button
            className="px-4 py-2 bg-slate-400 text-xl font-bold rounded-md shadow-md"
            type="submit"
          >
            Connect
          </button>
        </form>
      )}
      {endPoint && (
        <SocketProvider Endpoint={endPoint} setEndPoint={setEndPoint} />
      )}
    </main>
  );
}

export default App;
