import { MyEventID } from "../../types";
import { Area } from "@qiuz/react-image-map";
import { buttonClassName } from "./classes";
import { useDebouncedClick } from "../../utils/useDebouncedClick";

export default function FuncButtons({
  handleClick,
}: {
  handleClick: (MyEventID: MyEventID) => void;
}) {
  const areaStyles: Pick<Area, "width" | "height" | "style"> = {
    width: "11%",
    height: "4.7%",
    style: { mixBlendMode: "multiply" },
  };

  const buttons = [
    // First
    { left: "11.6%", top: "49.5%", EventID: MyEventID.CDU_INIT_REF },
    { left: "23.7%", top: "49.5%", EventID: MyEventID.CDU_RTE },
    { left: "35.9%", top: "49.5%", EventID: MyEventID.CDU_DEP_ARR },
    { left: "48.4%", top: "49.5%", EventID: MyEventID.CDU_ALTN },
    { left: "61%", top: "49.5%", EventID: MyEventID.CDU_VNAV },
    // Second
    { left: "11.6%", top: "55%", EventID: MyEventID.CDU_FIX },
    { left: "23.7%", top: "55%", EventID: MyEventID.CDU_LEGS },
    { left: "35.9%", top: "55%", EventID: MyEventID.CDU_HOLD },
    { left: "48.4%", top: "55%", EventID: MyEventID.CDU_FMCCOMMAND },
    { left: "61%", top: "55%", EventID: MyEventID.CDU_PROG },
    //Third
    { left: "11.6%", top: "60.7%", EventID: MyEventID.CDU_MENU },
    { left: "23.7%", top: "60.7%", EventID: MyEventID.CDU_NAV_RAD },
    //Fourth
    { left: "11.6%", top: "67%", EventID: MyEventID.CDU_PREV_PAGE },
    { left: "23.7%", top: "67%", EventID: MyEventID.CDU_NEXT_PAGE },
  ];

  const debouncedClick = useDebouncedClick(handleClick);

  return buttons.map(({ left, top, EventID }) => ({
    ...areaStyles,
    left,
    top,
    render: () => (
      <button
        className={buttonClassName}
        onMouseUp={() => {
          debouncedClick(EventID);
        }}
        onTouchStart={() => {
          debouncedClick(EventID);
        }}
      />
    ),
  })) as Area[];
}
